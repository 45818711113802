import { Api } from '@/api/api'
import { IAlliance } from '@/models/www/Alliances'
import { CRUD } from './crud'

class AlliancesApi implements CRUD<IAlliance> {
  private readonly apiGw: string
  private readonly apiWww: string

  constructor() {
    this.apiGw = process.env.API_GATEWAY
    this.apiWww = process.env.CH_WWW_API
  }

  public async findAll(): Promise<IAlliance[]> {
    return Api.get(`${this.apiGw}/ecommerce/api/v1/alliances`)
  }

  public async findById(id: string): Promise<IAlliance> {
    return Api.get(`${this.apiGw}/ecommerce/api/v1/alliances/${id}`)
  }

  public async delete(id: string): Promise<IAlliance> {
    return Api.del(`${this.apiWww}/private/ecommerce/alliances/${id}`)
  }

  public async upsert(alliance: IAlliance): Promise<IAlliance> {
    if (!alliance.id) delete alliance.id

    if (alliance.id) {
      return Api.put(`${this.apiWww}/private/ecommerce/alliances/${alliance.id}`, alliance)
    } else {
      return Api.post(`${this.apiWww}/private/ecommerce/alliances`, alliance)
    }
  }
}

export const AlliancesAPI = new AlliancesApi()
