
import { AlliancesAPI } from '@/api/alliances.api'
// import { ToastLife, ToastSeverities } from '../../models/components/toast/enums'
import DataTable from 'primevue/datatable'
import { useToast } from 'primevue/usetoast'
import EditAllianceDialog from '@/components/www/EditAllianceDialog.vue'
import { FilterMatchMode } from 'primevue/api'
import { IAlliance } from '@/models/www/Alliances'
import { defineComponent, onMounted, ref } from 'vue'
import { ToastLife } from '@/models/components/toast/enums'
import { useConfirm } from 'primevue/useconfirm'

export default defineComponent({
  name: 'AlliancesListView',
  components: {
    EditAllianceDialog,
    DataTable
  },
  setup () {
    const emptyAlliance: () => IAlliance = () => ({
      id: '',
      name: '',
      code: '',
      codeId: '',
      discount: '',
      logo: '',
      heroLogo: '',
      cardLogo: '',
      countries: [],
      description: '',
      backgroundColor: '#000000',
      showIconAsName: false,
      isAditional: false,
      fromDate: new Date(),
      toDate: new Date(),
      termsText: '',
      enabled: true
    })

    const toast = useToast()
    const loading = ref<boolean>(false)
    const alliances = ref<Array<IAlliance>>([])
    const expandedRows = ref<Array<IAlliance>>([])
    const showEditDialog = ref<boolean>(false)
    const editingAlliance = ref<IAlliance>(emptyAlliance())
    const showPreview = ref<boolean>(false)
    const confirm = useConfirm()
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const switchPreview = () => {
      showPreview.value = !showPreview.value

      if (showPreview.value) {
        expandedRows.value = alliances.value.filter(p => p.id)
      } else {
        expandedRows.value = []
      }
    }

    async function updateAllianceList (): Promise<void> {
      loading.value = true
      alliances.value = await AlliancesAPI.findAll()
      loading.value = false
    }

    const addNew = async () => {
      editingAlliance.value = emptyAlliance()

      showEditDialog.value = true
    }

    const onEdit = async (alliance: IAlliance) => {
      if (!alliance?.id) return
      const fetchedAlliance = await AlliancesAPI.findById(alliance?.id)
      if (!fetchedAlliance) return
      editingAlliance.value = fetchedAlliance
      if (!fetchedAlliance.codeId) {
        editingAlliance.value.code = ''
      } else {
        editingAlliance.value.code = fetchedAlliance.codeId
      }
      if (!fetchedAlliance.showIconAsName) editingAlliance.value.showIconAsName = false
      if (!fetchedAlliance.isAditional) editingAlliance.value.isAditional = false
      if (fetchedAlliance.discount) editingAlliance.value.discount = fetchedAlliance.discount.replace('%', '')
      showEditDialog.value = true
    }

    const confirmAllianceDeletion = (alliance: IAlliance) =>
      new Promise<boolean>((resolve) =>
        confirm.require({
          group: 'global',
          message: `¿Estás seguro de eliminar la alianza ${alliance.name}?`,
          header: '¡Alto ahí!',
          icon: 'pi pi-exclamation-triangle',
          accept: () => resolve(true),
          reject: () => resolve(false)
        })
      )

    const onDelete = async (alliance: IAlliance) => {
      try {
        if (!(await confirmAllianceDeletion(alliance))) return

        if (!alliance?.id) return
        await AlliancesAPI.delete(alliance?.id)

        toast.add({
          severity: 'success',
          summary: 'Alianza eliminada',
          detail: `La Alianza ${alliance.name} ha sido eliminada`,
          life: ToastLife.Default
        })

        await updateDeps()
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'Error al eliminar la Alianza',
          detail: (error instanceof Error && error.message) || '',
          life: ToastLife.Default
        })
      }
    }

    const onSaveAlliance = async () => {
      loading.value = true
      const alliance = editingAlliance.value
      if (alliance.discount) alliance.discount += '%'
      try {
        await AlliancesAPI.upsert(alliance)
        await updateDeps()
        toast.add({
          severity: 'success',
          summary: 'Alianza guardada',
          detail: `La Alianza ${alliance.name} ha sido guardada`,
          life: ToastLife.Default
        })
        showEditDialog.value = false
      } catch (error) {
        console.error(error)
        toast.add({
          severity: 'error',
          summary: 'Error al guardar la Alianza',
          detail: (error instanceof Error && error.message) || '',
          life: ToastLife.Default
        })
      }
      loading.value = false
    }

    const onCancelEditDialog = async () => {
      showEditDialog.value = false
    }

    const updateDeps = async () => {
      await updateAllianceList()
    }

    const setLoadingValue = (value: boolean) => {
      loading.value = value
    }

    onMounted(async () => {
      await updateDeps()
    })

    return {
      addNew,
      alliances,
      editingAlliance,
      loading,
      onCancelEditDialog,
      onEdit,
      onDelete,
      filters,
      onSaveAlliance,
      expandedRows,
      showEditDialog,
      switchPreview,
      setLoadingValue
    }
  }
})
