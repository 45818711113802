
import ProgressSpinner from 'primevue/progressspinner'
import CountrySelector from '../../components/www/CountrySelector.vue'
import UploadSvg from '@/components/uploadSvg/upload.vue'
import { IAlliance, loadersSVG } from '../../models/www/Alliances'
import Calendar from 'primevue/calendar'
import Textarea from 'primevue/textarea'
import SelectButton from 'primevue/selectbutton'
import Dialog from 'primevue/dialog'
import InputMask from 'primevue/inputmask'
import ColorPicker from 'primevue/colorpicker'
import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import { SvgProcessingOptions } from '@/models/www/SvgUploader'
import { FilterMatchMode } from 'primevue/api'

export default defineComponent({
  name: 'EditAllianceDialog',
  components: { CountrySelector, SelectButton, Dialog, Calendar, Textarea, InputMask, ColorPicker, ProgressSpinner, UploadSvg },
  emits: [
    'onCancel',
    'onSave', ''
  ],
  props: {
    event: {
      type: Object as PropType<IAlliance>,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const editableAlliance = reactive(props.event)
    const showSpeakersAdd = ref<boolean>(false)
    const loaders = ref<loadersSVG>({ logo: false, heroLogo: false, cardLogo: false })
    const processingOptions = ref<SvgProcessingOptions>({
      noSizeProcessing: false,
      noColorProcessing: true,
      noIdMasking: false,
      noImageUpload: false,
      noMinification: false
    })

    const visible = computed({
      get () {
        return props.show
      },
      set () {
        emit('onCancel')
      }
    })

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const validData = computed(() => !!(editableAlliance.name.trim()))

    const setLoadingValue = (id: string, value: boolean) => { loaders.value[id] = value }

    const onSave = () => {
      emit('onSave', editableAlliance)
    }

    const closeEditDialog = () => {
      emit('onCancel')
    }

    const states = [
      {
        name: 'Activo',
        code: true
      },
      {
        name: 'Inactivo',
        code: false
      }
    ]

    const showAsNameStates = [
      {
        name: 'Si',
        code: true
      },
      {
        name: 'No',
        code: false
      }
    ]

    return {
      editableAlliance,
      showSpeakersAdd,
      setLoadingValue,
      closeEditDialog,
      processingOptions,
      onSave,
      loaders,
      visible,
      validData,
      states,
      filters,
      showAsNameStates
    }
  }
})
